.trial-card {
  bottom: 10px;
  right: 50px;
  z-index: 1001;
  max-width: 300px;
  margin-left: 10px;
}

@media (max-width: 540px) {
  .trial-card {
    max-width: 85%;
    right: 10px;
  }
}

.trial-card h6 {
  margin: 0px;
  margin-right: 50px;
  font-size: 17px;
}

.feedback-box {
  bottom: 50%;
  right: -35px;
  z-index: 1001;
  width: auth;
  border-radius: 6px 6px 0px 0px;
  transform: rotate(-90deg);
}

.trial-card.bg-dark {
  background-color: #fff !important;
  color: #000 !important;
}

.trial-card.bg-dark h6 {
  color: #000;
}

.trial-card.bg-dark .btn {
  background-color: #000;
  color: #fff;
}

.trial-card.bg-light {
  background-color: #000 !important;
  color: #fff !important;
}

.trial-card.bg-light h6 {
  color: #fff;
}

.trial-card.bg-light .btn {
  background-color: #fff;
  color: #000;
}
