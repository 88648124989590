/* *{
  border: 1px solid red
} */
.variant {
  padding: 10px 10px;
  border-bottom: 2px solid var(--vz-border-color);
}

.variant:last-child {
  border-bottom: none;
}

.variant .variant__image {
  width: 100px;
  height: 100px;
  border: 2px dashed var(--vz-border-color);
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
}

.variant .variant__label {
  text-align: center;
  padding: 20px 10px;
  font-size: 13px;
  font-weight: 500;
  color: var(--vz-heading-color);
}

.variant .variant__label span {
  display: block;
  padding: 0px 3px;
}

.img-display {
  position: relative;
}

.img-display__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.237);
  display: none;
}

.em-overlay {
  background-color: transparent;
  transition: 0.3s;
}

.em-overlay:hover {
  background-color: rgba(21, 21, 21, 0.2);
}

.theme-overlay {
  background-color: rgba(21, 21, 21, 0.44);
  display: none;
  transition: 0.3s;
  z-index: 100;
}

.theme-card:hover .theme-overlay {
  display: block;
  transition: 0.3s;
}
.upload_video{
  height: 300px
}
.variant .variant__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1100;
}

.variant .variant__popup .variant__popup__dialog {
  margin: 100px auto;
  width: 550px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 5px rgba(66, 53, 82, 0.142);
  border-radius: 5px;
  padding: 20px 30px;
}

.variant .variant__pop .variant__popup__dialog .variant_popup_dialog__header {
  padding: 10px 0px;
  font-size: 17px;
}

.variant_popup__dialog__body {
  height: 350px;
}

.variant__popup__image {
  max-width: 25%;
  display: inline-block;
  border-radius: 7px;
  overflow: hidden;
  padding: 1px;
  position: relative;
  cursor: pointer;
  border: 2px solid #fff;
}

.variant__popup__image:hover,
.variant__popup__image.selected {
  border: 2px solid var(--vz-purple);
}

.variant__popup__image .form-check {
  position: absolute;
  left: 5px;
  top: 3px;
}

.variant__popup__image img {
  width: 100%;
  border-radius: 5px;
}

.img-display:hover .img-display__overlay {
  display: block;
}

.img-display__overlay__remove-btn {
  position: absolute;
  cursor: pointer;
  top: -3px;
  right: -3px;
  padding: 5px 7px;
  border-radius: 15px;
  background-color: red;
  color: #fff;
}

.input-group-text {
  display: -webkit-box !important;
}

.startDate::-webkit-datetime-edit {
  position: relative;
  left: 2rem;
}

.startDate::-webkit-calendar-picker-indicator {
  display: inline-block;
  position: absolute;
  left: 1rem;
}

.filter-btn {
  padding: 8px 2px;
}
.mobile {
  display: none;
}

@media (max-width: 720px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

}
.nav-tabs .tab:hover,
.nav-tabs .tab.active {
  color: #11d1b7;
  cursor: pointer;
}

.edit-product-container {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
}

.edit-product-label {
  margin-right: 2rem;
}

/* -----------LOGO */
.imageContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 16;
}

.imagePath {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #eaeaea;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.tj-upload-img {
  width: 100%;
  object-fit: cover;
}

.plan-cards:hover {
  background-color: rgba(105, 94, 239, 0.1);
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

/* --------------- */

.placeholder {
  background-color: #c9c9c9 !important;
  border-radius: 20px;
}

.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.3) 75%,
    #000 95%
  );
}

@media (min-width: 576px) {
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
}

@media (max-width: 576px) {
  .nav.nav-tabs-custom {
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
}

.page-content {
  padding: 95px calc(1.5rem / 2) 60px calc(1.5rem / 2) !important;
}

.navbar-menu .navbar-nav .nav-link {
  padding: 0.125rem 1.2rem !important;
}

.todo-task-card {
  margin-bottom: 1.5rem;
}

@media (max-width: 540px) {
  .todo-task-card {
    margin-bottom: 0;
  }
}

.task-card {
  background-color: #fff;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
}

.task-card.dark {
  background-color: var(--vz-card-bg);
}

.task-card-box {
  background-color: #fff;
}

.task-card-box.dark {
  background-color: var(--vz-card-bg);
}

.task-card span.index {
  border: 2px solid var(--vz-border-color);
  text-align: center;
  padding: 7px 12px;
  font-size: 17px;
  font-weight: bold;
  border-radius: 20px;
  color: #000;
}

.task-card.dark span.index {
  color: #fff;
}

.provider-card:hover,
.provider-card.selected {
  cursor: pointer;
  border-color: var(--vz-purple) !important;
}

.link:hover{
  cursor: pointer;
}
.select-item{
  cursor: pointer;
  
}
.select-item:hover{
 background-color: #E6E6FA;
}