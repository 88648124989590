.loader {
  /* margin: 0 auto; */
  width: 60px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}
.loader > div {
  height: 100%;
  width: 5px;
  display: inline-block;
  float: left;
  margin-left: 2px;
  -webkit-animation: delay 0.8s infinite ease-in-out;
  animation: delay 0.8s infinite ease-in-out;
  background-color: #8a50fc;
}

.loader .bar2 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.loader .bar3 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.loader .bar4 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.loader .bar5 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.loader .bar6 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

@-webkit-keyframes delay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.05);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes delay {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
